import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { LanguageContext } from "./../utils/LanguageContext"

function Seo({
  noindex,
  description,
  language,
  meta,
  pathname,
  keywords,
  title,
}) {
  const { site } = useStaticQuery(
    graphql`
      query seoMetadata {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  if (noindex) {
    return (
      <Helmet
        meta={[{ name: "robots", content: "noindex" }]}
        htmlAttributes={{ lang: language === "English" ? "en" : "es" }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
      />
    )
  }

  const url = `${site.siteMetadata.siteUrl}${pathname || "/"}`
  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{ lang: language === "English" ? "en" : "es" }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `yandex-verification`,
          content: "95cd7c40a93c6410",
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

Seo.defaultProps = {
  meta: [],
  keywords: [`women`, `fashion`, `design`, `clothes`],
}

Seo.propTypes = {
  noindex: PropTypes.bool,
  description: PropTypes.string,
  language: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
  title: PropTypes.string,
}

const WithContext = Component => {
  return props => (
    <LanguageContext.Consumer>
      {state => <Component {...props} language={state?.language}></Component>}
    </LanguageContext.Consumer>
  )
}

export default WithContext(Seo)
