/* !!! To be used internally by LanguageContext.js ONLY */

let browserLanguage
let languages
const SPANISH = "Spanish"
const ENGLISH = "English"

try {
  languages = window.navigator.languages.filter(
    language => language === "es" || language === "es-ES"
  )
  browserLanguage = languages && languages.length ? SPANISH : ENGLISH
} catch (e) {
  browserLanguage = ENGLISH
}

export default browserLanguage
