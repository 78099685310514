import React from "react"
import Cookies from "universal-cookie"

import browserLanguage from "./BrowserLanguage"

const LanguageContext = React.createContext()

class LanguageProvider extends React.Component {
  state = {
    language: browserLanguage || "English",
    setLanguage: this.setLanguage.bind(this),
  }

  componentDidMount() {
    this.cookies = new Cookies()
    const language = this.cookies.get("USER_LANGUAGE")
    if (!!language) {
      this.setState({ language })
    }
  }

  setLanguage(event) {
    event.preventDefault()
    const language = event.target.value

    this.setState(
      {
        language: language,
      },
      () => this.setLanguageCallback(language)
    )
  }

  setLanguageCallback(language) {
    const now = new Date()
    // set cookie expiry in 200 days
    const expiry = now.setDate(now.getDate() + 200)
    this.cookies.set("USER_LANGUAGE", language, {
      path: "/",
      expires: new Date(expiry),
    })
    // console.log(`LanguageContext -> LanguageProvider -> Set cookie USER_LANGUAGE to ${language}`);
  }

  render() {
    return (
      <LanguageContext.Provider value={this.state}>
        {this.props.children}
      </LanguageContext.Provider>
    )
  }
}

export { LanguageContext }

export default LanguageProvider
